import { useCallback, useEffect, useMemo, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, ORIGIN, STATUS } from 'react-joyride';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import Tooltip from './components/Tooltip';
import TourControlButton from './components/ControlButton';

import { defaultValue, OnBoardContext } from './context';
import onBoardSteps from './steps';
import { urlWithTours } from './constants';

export function OnBoard({ children }) {
  const { pathname } = useLocation();

  const [run, setRun] = useState(false);
  const [isAutoRun, setIsAutoRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const [startedTourName, setStartedTourName] = useState(null);
  const [helpers, setHelpers] = useState(defaultValue.helpers);

  const [path, isHunterHomePage] = useMemo(() => [pathname, pathname.includes('member-hr/home')], [pathname]);

  const restartTour = useCallback(isAutoRun => {
    setStepIndex(0);

    if (isAutoRun) setRun(true);
  }, []);

  const contextValue = useMemo(
    () => ({
      setTour: (tourName, isAutoRun = false) => {
        if (startedTourName && startedTourName === tourName) restartTour(isAutoRun);
        else {
          setIsAutoRun(isAutoRun);
          setStartedTourName(() => tourName);
        }
      },
      helpers,
    }),
    [helpers],
  );

  useEffect(() => {
    let timer;

    if (startedTourName && isAutoRun) {
      // NOTE: need to wait all renders. Looks like as crutch
      timer = setTimeout(() => {
        const tourSteps = [...onBoardSteps[startedTourName]];

        // NOTE: need for running tour without additional click on beacon
        tourSteps[0].disableBeacon = true;

        setSteps(tourSteps);
        restartTour(isAutoRun);
      }, 500);
    }

    return () => {
      setRun(false);
      clearTimeout(timer);
    };
  }, [isAutoRun]);

  useEffect(() => {
    if (steps[stepIndex]?.navigateTo && steps[stepIndex]?.navigateTo === pathname) helpers.next();
  }, [pathname]);

  const handleJoyrideCallback = data => {
    const { action, index, origin, status, type, _step } = data;

    if (ORIGIN.BUTTON_CLOSE === origin) {
      setRun(() => false);
      setIsAutoRun(() => false);
    } else if (EVENTS.TARGET_NOT_FOUND === type) {
      setStartedTourName(() => null);
      setRun(() => false);
      setIsAutoRun(() => false);
    } else if (EVENTS.STEP_AFTER === type) {
      setStepIndex(() => index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(() => false);
      setIsAutoRun(() => false);
    }
  };

  return (
    <OnBoardContext.Provider value={contextValue}>
      {run && (
        <Joyride
          callback={handleJoyrideCallback}
          run={run}
          stepIndex={stepIndex}
          steps={steps}
          continuous
          spotlightClicks
          getHelpers={setHelpers}
          showProgress
          styles={{ options: { zIndex: 1250 } }}
          disableOverlayClose
          tooltipComponent={Tooltip}
          locale={{ last: 'Ok' }}
          scrollDuration="400"
        />
      )}

      {urlWithTours.some(url => path.includes(url)) && (
        <TourControlButton
          run={run}
          setRun={setRun}
          setIsAutoRun={setIsAutoRun}
          setStepIndex={setStepIndex}
          isHunterHomePage={isHunterHomePage}
        />
      )}

      <Box height="100%">{children}</Box>
    </OnBoardContext.Provider>
  );
}
